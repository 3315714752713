import { Company, getCurrentUser, RELATION_KEY_URL, setCurrentUser, URLS, User } from '@netcurio/frontend-common'
import { t } from 'i18next'
import { FC, ReactNode, useEffect, useState } from 'react'
import { DROPDOWN_TYPES, DropdownMain } from '../DropdownMain/DropdownMain'
import { NetcurioButton } from '../NetcurioButton/NetcurioButton'
import { NetcurioIcons } from '../NetcurioIcons/NetcurioIcons'
import { NetcurioTooltip } from '../NetcurioTooltip/NetcurioTooltip'
import { ChangeCompanyHeaderModal } from './ChangeCompanyHeaderModal/ChangeCompanyHeaderModal'
import styles from './header.module.scss'

interface HeaderProps {
	children?: ReactNode
}

/**
 * Header component
 * @param children <ReactNode> The structure of this children should be as follows:
 * - Two containers (div) are expected, but are optional
 * - By default the stack is aligned to the right
 * - If you want to show only a left container, you must send the two expected containers,
 *   but the right container send empty
 * @return Component
 * */

export const Header: FC<HeaderProps> = ({ children }) => {
	const [openModalCompany, setOpenModalCompany] = useState<boolean>(false)
	const [companyRFC, setCompanyRFC] = useState<string>('')
	const [user, setUser] = useState<User>({})

	useEffect(() => {
		const user: User = getCurrentUser()
		setUser(user)
	}, [])

	const changeCompany = (company) => {
		setCompanyRFC(company.value)
		setOpenModalCompany(true)
	}

	const acceptActionModal = () => {
		const company: Company = user.companies.find((itemCompany) => itemCompany.rfc === companyRFC)
		setCurrentUser(user, company)
		location.href = company.homepage ? URLS[RELATION_KEY_URL[company.homepage]] : URLS.PO_LIST
	}

	return (
		<header className={styles.headerSection}>
			{children ?? <div></div>}
			<div className={styles.containerBrandNetcurio}>
				{user && user?.companies?.length > 1 && (
					<div className={styles.companyContainer}>
						<DropdownMain
							data={user?.companies}
							menuType={DROPDOWN_TYPES.COMPANY}
							defaultValue={t('companyChange')}
							label={user?.company?.name}
							onChange={changeCompany}
						/>
					</div>
				)}
				<div className={styles.helpContainer}>
					<NetcurioTooltip title={t('helpButton')}>
						<NetcurioButton
							endIcon={<NetcurioIcons.HelpOutline />}
							onClick={() => {
								window.open(URLS.HELP, '_blank')
							}}
							variant="borderless"
						>
							{t('helpButton')}
						</NetcurioButton>
					</NetcurioTooltip>
				</div>
				<ChangeCompanyHeaderModal
					open={openModalCompany}
					close={() => setOpenModalCompany(false)}
					accept={acceptActionModal}
				/>
			</div>
		</header>
	)
}
