import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useClient } from '../../../hooks/useClient'
import { useUpdateEffect } from '../../../hooks/useUpdateEffect'
import { CreditMemo } from '../../../types'
import { showErrorComponent } from '../../../utilities/errorCode'
import { expiredToken } from '../../../utilities/expiredToken'
import { getUrlParameter } from '../../../utilities/getUrlParameter'
import { useDebounce } from '../../../utilities/useDebounce'
import { REMOVE_ASSOCIATION, REQUEST_FOR_CREDIT_MEMOS_FOR_CM } from '../queries'
import { AutoCompleteOption, WorkflowValueEnum } from '../types'

interface UseDocumentAssociationProps {
	creditMemo: CreditMemo | null
	onAssociateDocument: (rfcmId: string | undefined, items: unknown) => void
	onRefreshDocumentData: () => void
}

export const useDocumentAssociation = ({
	creditMemo,
	onAssociateDocument,
	onRefreshDocumentData
}: UseDocumentAssociationProps) => {
	const { t } = useTranslation()
	const [isRemoveAssociationModalOpen, setIsRemoveAssociationModalOpen] = useState(false)
	const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)
	const [errorCode, setErrorCode] = useState<any>(null)
	const [searchTerm, setSearchTerm] = useState('')
	const [loading, setLoading] = useState(false)
	const [currentAssociation, setCurrentAssociation] = useState<AutoCompleteOption | null>(null)
	const [pendingSelectedOption, setPendingSelectedOption] = useState<AutoCompleteOption | null>(null)
	const [documentSuggestions, setDocumentSuggestions] = useState([])
	const debouncedSearchTerm = useDebounce(searchTerm, 500)
	const client = useClient()

	useUpdateEffect(() => {
		if (debouncedSearchTerm) {
			fetchDocumentSuggestions(debouncedSearchTerm)
		} else {
			setDocumentSuggestions([])
		}
	}, [debouncedSearchTerm])

	const fetchDocumentSuggestions = (search: string) => {
		const query = REQUEST_FOR_CREDIT_MEMOS_FOR_CM
		const variables = {
			search_text: search,
			customer: creditMemo?.receiver.rfc
		}
		setLoading(true)

		client
			.query({
				query,
				variables
			})
			.then((result) => {
				setDocumentSuggestions(result.data.RequestForCreditMemosForCM)
			})
			.catch((error) => {
				console.error(error)
				const errorCode = showErrorComponent(error)
				if (!expiredToken(errorCode)) {
					setIsErrorModalOpen(true)
					setErrorCode(errorCode)
				}
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const handleCloseRemoveAssociationModal = () => {
		setIsRemoveAssociationModalOpen(false)
	}

	const handleRemoveAssociation = () => {
		const documentId = getUrlParameter('creditmemo')

		const queryToRemoveAssociation = REMOVE_ASSOCIATION
		client
			.mutate({
				mutation: queryToRemoveAssociation,
				variables: {
					uuid: documentId
				}
			})
			.then(() => {
				onAssociateDocument(undefined, [])
				onRefreshDocumentData()
				setIsRemoveAssociationModalOpen(false)
				if (pendingSelectedOption) {
					associateDocument(pendingSelectedOption)
					setPendingSelectedOption(null)
				} else {
					setCurrentAssociation(null)
				}
			})
			.catch((error) => {
				const errorCode = showErrorComponent(error)
				if (!expiredToken(errorCode)) {
					setIsRemoveAssociationModalOpen(false)
					setErrorCode(errorCode)
				}
			})
	}

	const associateDocument = (selected: AutoCompleteOption) => {
		setCurrentAssociation(selected)
		onAssociateDocument(selected?.id, selected.items)
	}

	const handleSelectOption = (selected: AutoCompleteOption) => {
		if (currentAssociation) {
			setIsRemoveAssociationModalOpen(true)
			setPendingSelectedOption(selected)
		} else {
			associateDocument(selected)
		}
	}

	const workFlowOptions: {
		label: string
		value: WorkflowValueEnum
	}[] = [
		{ label: t('NC_advance_discount'), value: WorkflowValueEnum.AdvanceDiscount },
		{ label: t('other_credit_notes'), value: WorkflowValueEnum.OtherCreditMemo }
	]

	return {
		searchTerm,
		setSearchTerm,
		loading,
		documentSuggestions,
		currentAssociation,
		handleSelectOption,
		isRemoveAssociationModalOpen,
		handleCloseRemoveAssociationModal,
		handleRemoveAssociation,
		isErrorModalOpen,
		errorCode,
		setIsRemoveAssociationModalOpen,
		workFlowOptions
	}
}
