import { getCurrentUser, URLS } from '@netcurio/frontend-common'
import ApolloClient from 'apollo-boost'
import { defaultDataIdFromObject, InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory'
import { Auth } from 'aws-amplify'
import { showModalExpiredSession } from '../components/expiredSessionMessage'
import Constants from './constants'

export const refreshToken = (motive = '') => {
	if (motive === Constants.ERROR.INVALID_TOKEN) {
		Auth.signOut()
			.then(() => {
				sessionStorage.setItem('correctMessage', Constants.ERROR.INVALID_TOKEN)
				localStorage.clear()
				location.href = URLS.LOGIN
			})
			.catch((err) => console.log(err))
	} else {
		Auth.currentAuthenticatedUser()
			.then((cognitoUser) =>
				Auth.currentSession().then((currentSession) => {
					cognitoUser.refreshSession(currentSession.getRefreshToken(), (err: any) => {
						if (err) {
							console.error(err)
						} else {
							showModalExpiredSession()
						}
					})
				})
			)
			.catch((err) => {
				if (err !== Constants.ERROR.AWS_NOT_AUTHENTICATED_ERROR) {
					console.error('Unable to refresh Token', err)
				}
			})
	}
}

export function connection(): ApolloClient<NormalizedCacheObject> {
	const currentUser = getCurrentUser()
	refreshToken()
	return new ApolloClient({
		uri: '/api/graphql',
		request: async (operation) => {
			const authorization = await Auth.currentAuthenticatedUser()
				.then((user) => {
					return user.signInUserSession.idToken.jwtToken
				})
				.catch((err) => {
					console.error(err)
				})
			operation.setContext({
				headers: {
					...(authorization ? { authorization: `Bearer ${authorization}` } : {}),
					...(currentUser && currentUser.company
						? { 'current-company': currentUser.company.rfc }
						: {})
				}
			})
		},
		cache: new InMemoryCache({
			addTypename: false,
			dataIdFromObject: (object: unknown) => {
				switch (object.__typename) {
					case 'BranchOffice':
						return `BranchOffice:${object.company.rfc}:${object.id}`
					case 'Comment':
						return `Comment:${object.uuid}`
					case 'Company':
						return `Company:${object.rfc}`
					case 'GoodsReceipt':
						return `GoodsReceipt:${object.customer.rfc}:${object.id}`
					case 'Invitation':
						return `Invitation:${object.uuid}`
					case 'Invoice':
						return `Invoice:${object.uuid}`
					case 'PaymentComplement':
						return `PaymentComplement:${object.uuid}`
					case 'Product':
						return `Product:${object.supplier.rfc}:${object.id}`
					case 'PurchaseOrder':
						return `PurchaseOrder:${object.customer.rfc}:${object.id}`
					case 'Quotation':
						return `Quotation:${object.supplier.rfc}:${object.id}`
					case 'RequestForQuotation':
						return `RequestForQuotation:${object.customer.rfc}:${object.id}`
					case 'User':
						return `User:${object.email}`
					case 'UserLogin':
						return `UserLogin:${object.email}`
					case 'Consumption':
						return `Consumption:${object.customer.rfc}:${object.id}`
					case 'ConsignmentStock':
						return `ConsignmentStock:${object.product.id}:${object.customer}:${object.product.supplier.rfc}:${object.branch_office}`
					case 'RequestForCreditMemo':
						return `RequestForCreditMemo:${object.customer.rfc}:${object.id}`
					case 'CreditMemo':
						return `CreditMemo:${object.uuid}`
					case 'StorageLocation':
						return `StorageLocation:${object.id}:${object.branch_office}:${object.company}`
					case 'Batch':
						return `Batch:${object.id}:${object.goods_receipt}:${object.customer}:${object.goods_receipt_position}`
					case 'EnrollmentInvitation':
						return `Batch:${object.id}:${object.supplier.rfc}`
					case 'Ticket':
						return `Ticket:${object.id}:${object.sender.rfc}`
					default:
						return defaultDataIdFromObject(object)
				}
			}
		})
	})
}

export const getAuthToken = async () => {
	const user = await Auth.currentAuthenticatedUser()
	return user.signInUserSession.idToken.jwtToken
}
