import { URLS } from '@netcurio/frontend-common'
import {
	FilePairs,
	FileResult,
	FileUpload,
	NetcurioButton,
	NetcurioDialog,
	PairFilesIcon,
	ProcessFileUpload,
	Severity,
	ValidationFiles
} from '@netcurio/frontend-components'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useClient } from '../../../hooks/useClient'
import { ExtraRequestParams } from '../../../types/FileUpload'
import Constants from '../../../utilities/constants'
import { FileEntry, uploadFiles } from '../../../utilities/file-handling/upload-files'
import {
	validateFilesHavePairs,
	validateNewFiles,
	validateSizeOfFiles,
	validateTypeOfFile
} from '../../../utilities/uploadFilesFuctions'
import { CREATE_INVOICE } from '../graphql/create-invoice'
import styles from './NewInvoiceModal.module.scss'

interface NewInvoiceModalProps {
	open: boolean
	onClose: () => void
	redirectToDetail?: (detail: string) => void
	extraRequestParams?: ExtraRequestParams
	isCustomer: boolean
	companyHasStandAloneEnable: boolean
}

/**
 * NewInvoiceModal
 * @param open <boolean>: If `true`, the component is shown.
 * @param onClose <void>: Callback fired when the component requests to be closed.
 * @param redirectToDetail <(detail: string) => void>: Function to execute a redirect
 * @param extraRequestParams <ExtraRequestParams>: Extra params for the request to the backend
 * @param isCustomer<boolean> validate is the actual user is customer
 * @param companyHasStandAloneEnable<boolean> validate is the actual company has the variable standalone_invoice_workflow_enabled enable
 * @returns component
 */
export function NewInvoiceModal({
	open,
	onClose,
	redirectToDetail,
	extraRequestParams,
	isCustomer,
	companyHasStandAloneEnable
}: NewInvoiceModalProps) {
	const { t } = useTranslation()
	const client = useClient()
	const { SELECT, UPLOADING, FINISHED } = ProcessFileUpload
	const { PDF, XML } = Constants.MIME_TYPES

	const acceptFileTypes = `${PDF},${XML}`

	const [canUpload, setCanUpload] = useState(false)
	const [stopUpload, setStopUpload] = useState(false)
	const [process, setProcess] = useState<ProcessFileUpload>(SELECT)
	const [filesToUpload, setFilesToUpload] = useState<Array<File>>([])
	const [filesResult, setFilesResult] = useState<Array<FileResult>>([])

	const filePairs: FilePairs = useMemo(() => {
		return filesToUpload.reduce((group: Record<string, any>, file) => {
			const nameTrimmed = file.name.substring(0, file.name.lastIndexOf('.'))
			group[nameTrimmed] = group[nameTrimmed] ?? { files: [], isLoading: false }
			group[nameTrimmed].files.push(file)
			return group
		}, {})
	}, [filesToUpload])

	const filesErrorCounts = useMemo(() => {
		const filesPairs = Object.values(filePairs).map((fp) => fp.files) as Array<Array<File>>
		const filesNoPairs = filesPairs.filter((files) => files.length < 2).flat()
		return filesNoPairs.reduce((counts, file) => ({ ...counts, [file.type]: counts[file.type] + 1 }), {
			[PDF]: 0,
			[XML]: 0
		})
	}, [filePairs, PDF, XML])

	const pairFilesUploaded = useMemo(
		() => filesResult.filter((file) => file.icon === PairFilesIcon.Success).length,
		[filesResult]
	)

	useEffect(() => {
		return () => {
			setProcess(SELECT)
			setFilesToUpload([])
			setStopUpload(false)
		}
	}, [open, SELECT])

	useEffect(() => setFilesResult(Object.values(filePairs)), [filePairs])
	const typeOfFileValidation = (files: Array<File>) => validateTypeOfFile(files, acceptFileTypes)
	const filesHavePairs = () => validateFilesHavePairs(filesErrorCounts)
	const validateFilesToUpload = [typeOfFileValidation, validateSizeOfFiles, filesHavePairs]

	const filesUploaded = (filesResult: Array<FileResult>): ValidationFiles => {
		const total = filesResult.length
		const updated = pairFilesUploaded
		const CFDIName = t('invoiceName')
		return {
			severity: total === updated ? Severity.Success : Severity.Info,
			message: t('updatedBillsText', { updated, total, CFDIName })
		}
	}

	const associationNeeded = (): ValidationFiles | void => {
		if (!(isCustomer && companyHasStandAloneEnable) && pairFilesUploaded > 0 && !extraRequestParams) {
			return { severity: Severity.Info, message: t('messageAssociationNeeded') }
		}
	}

	const nextSteps = () => {
		if (process === FINISHED && pairFilesUploaded) {
			return {
				severity: Severity.Info,
				message: t('followStepsInvoiceDetails')
			}
		}
		return
	}

	const validateFilesResult = [nextSteps, filesUploaded, associationNeeded]

	const processInvoiceCreation = useCallback(
		async (files: FileEntry[], extraRequestParams?: ExtraRequestParams) => {
			const xmlFile = files.find((file) => {
				return file.name.includes('.xml')
			})
			const pdfFile = files.find((file) => {
				return file.name.includes('.pdf')
			})
			return await client
				.mutate({
					mutation: CREATE_INVOICE,
					variables: {
						xml: xmlFile,
						pdf: pdfFile,
						...(extraRequestParams?.reference !== undefined
							? { reference: extraRequestParams?.reference }
							: {}),
						...(extraRequestParams?.referenceType !== undefined
							? { referenceType: extraRequestParams?.referenceType }
							: {})
					}
				})
				.then((response) => {
					return response.data.createInvoice
				})
				.catch((error) => {
					return { error: error.message }
				})
		},
		[client]
	)

	const submitFiles = useCallback(
		async (controller: AbortController, stopUpload: boolean) => {
			if (!stopUpload) {
				const { UPLOADING_STOPPED } = Constants.UPLOAD_FILES.UPLOAD_FILES_ERRORS
				for (const [fileName, filePairsObj] of Object.entries(filePairs)) {
					filePairs[fileName].isLoading = true
					setFilesResult(Object.values(filePairs))

					const uploadResponse = await uploadFiles(filePairsObj.files, controller.signal)

					if (uploadResponse.successfulResponse) {
						const response = await processInvoiceCreation(
							uploadResponse.successfulResponse,
							extraRequestParams
						)
						if (response.uuid) {
							filePairs[fileName].icon = PairFilesIcon.Success
							filePairs[fileName].UUID = response.uuid
						} else {
							filePairs[fileName].icon = PairFilesIcon.Warning
							filePairs[fileName].errorText = t(response.error ?? '')
						}
					} else if (uploadResponse.unknownError === '20') {
						filePairs[fileName].icon = PairFilesIcon.Stop
						filePairs[fileName].errorText = t(UPLOADING_STOPPED)
					} else {
						filePairs[fileName].icon = PairFilesIcon.Warning
						filePairs[fileName].errorText = t(uploadResponse.unknownError ?? '')
					}
					filePairs[fileName].isLoading = false
					setFilesResult(Object.values(filePairs))
				}
				setProcess(FINISHED)
			}
		},
		[FINISHED, filePairs, processInvoiceCreation, extraRequestParams, t]
	)

	useEffect(() => {
		let controller: AbortController
		if (process === UPLOADING) {
			controller = new AbortController()
			submitFiles(controller, stopUpload)
		}
		return () => {
			controller?.abort()
		}
	}, [submitFiles, process, stopUpload, UPLOADING])

	const updateFilesToUpload = (fileList: FileList) =>
		setFilesToUpload((state) => validateNewFiles(fileList, state))

	const cancelUpload = () => setStopUpload(true)
	const processData = () => setProcess(UPLOADING)
	const deleteFile = (index: React.Key) => setFilesToUpload((state) => state.filter((_, i) => i !== index))
	const closeDialog = () => {
		onClose()
		if (
			redirectToDetail &&
			!extraRequestParams &&
			pairFilesUploaded === 1 &&
			pairFilesUploaded === filesResult.length
		) {
			redirectToDetail(filesResult[0].UUID ?? URLS.INVOICE_LIST)
		} else if (pairFilesUploaded > 0) {
			if (window.location.pathname === URLS.DASHBOARDS) location.href = URLS.INVOICE_LIST
			else location.reload()
		}
	}

	const actionButtons = (
		<div className={styles.actionButtonsForDialog}>
			<NetcurioButton variant="text" onClick={closeDialog} disabled={process === UPLOADING}>
				{process === SELECT ? t('cancelButton') : t('closeText')}
			</NetcurioButton>
			{process === SELECT ? (
				<NetcurioButton variant="contained" onClick={processData} disabled={!canUpload}>
					{t('processText')}
				</NetcurioButton>
			) : process === FINISHED ? (
				<NetcurioButton onClick={processData} disabled={true}>
					{t('stopText')}
				</NetcurioButton>
			) : (
				<NetcurioButton variant="contained" onClick={cancelUpload} disabled={process !== UPLOADING}>
					{t('stopText')}
				</NetcurioButton>
			)}
		</div>
	)

	return (
		<NetcurioDialog
			open={open}
			actionButtons={actionButtons}
			titleText={t('uploadNewInvoice')}
			minWidth="960px"
			maxWidth="960px"
		>
			<FileUpload
				fileUploadDescription={[t('fileInvoiceFormats'), t('associateInvoiceSteps')]}
				dragAndDropText={t('dragAndDrop')}
				waitUploadFilesText={t('invoicesBeingProcessed')}
				acceptFileTypes={acceptFileTypes}
				acceptMultipleFiles={true}
				process={process}
				filesToUpload={filesToUpload}
				filesResult={filesResult}
				validateFilesToUpload={validateFilesToUpload}
				validateFilesResult={validateFilesResult}
				setCanUpload={setCanUpload}
				updateFilesToUpload={updateFilesToUpload}
				deleteFile={deleteFile}
			/>
		</NetcurioDialog>
	)
}
