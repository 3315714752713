import React from 'react'
import App from './app'
import { useEnvironment } from './hooks/useEnvironment'
import { initializeAmplify } from './utilities/initialize-amplify'
export const Bootstrap = () => {
	const { environment, isSuccess } = useEnvironment()
	if (isSuccess) {
		const region = environment?.COGNITO_REGION || ''
		const poolid = environment?.COGNITO_USERPOOLID || ''
		const clientid = environment?.COGNITO_CLIENTID || ''
		initializeAmplify(region, poolid, clientid)
	}

	return <App />
}
