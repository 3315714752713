import logo from '@netcurio/frontend-assets/src/images/secondaryBlueNavyLogo.svg'
import coverImage from '@netcurio/frontend-assets/src/imagesNewDesign/landingPageLogin.jpg'
import coverImageWebp from '@netcurio/frontend-assets/src/imagesNewDesign/landingPageLogin.webp'
import { URLS } from '@netcurio/frontend-common'
import {
	NetcurioButton,
	NetcurioGrid,
	NetcurioTextField,
	useNetcurioLoader
} from '@netcurio/frontend-components'
import { Auth } from 'aws-amplify'
import classNames from 'classnames'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import HomeView from '../../app'
import { useEnvironment } from '../../hooks/useEnvironment'
import { connection } from '../../utilities/connection'
import Constants from '../../utilities/constants'
import { showErrorComponent } from '../../utilities/errorCode'
import { getUrlParameter } from '../../utilities/getUrlParameter'
import { loadRecaptchaScript } from '../../utilities/load-re-captcha-script'
import { validationToRedirection } from '../../utilities/redirectHelper'
import UserInformation from '../../utilities/userInformation'
import { RegisterCompanyModal } from '../registerCompanyModal/RegisterCompanyModal'
import {
	ShowRegisterCompanyContext,
	ShowRegisterCompanyProvider
} from '../registerCompanyModal/ShowRegisterCompanyContext'
import { DefaultCompanyBlockModal } from './Modals/DefaultCompanyBlockModal/DefaultCompanyBlockModal'
import { DefaultCompanyUnsubscribedModal } from './Modals/DefaultCompanyUnsubscribedModal/DefaultCompanyUnsubscribedModal'
import styles from './login.module.scss'
import * as queries from './queries'

const LoginComponent = () => {
	const { t } = useTranslation()
	const { showLoadingSpinner, hideLoadingSpinner } = useNetcurioLoader()
	const { setIsOpenRegisterCompanyModal, topMessage, setTopMessage } =
		useContext(ShowRegisterCompanyContext)
	const client = connection()

	const [valueUserName, setValueUserName] = useState('')
	const [valueUserPassword, setValueUserPassword] = useState('')
	const [borderRedPass, setBorderRedPass] = useState(false)
	const [borderRedEmail, setBorderRedEmail] = useState(false)
	const [errorMessageLogin, setErrorMessageLogin] = useState('')
	const [pleaseCheck, setPleaseCheck] = useState(false)
	const [blockDefaultCompany, setBlockDefaultCompany] = useState<boolean>(false)
	const [unsubscribedFromDefaultCompany, setUnsubscribedFromDefaultCompany] = useState<boolean>(false)
	const { environment, isSuccess } = useEnvironment()

	useEffect(() => {
		if (sessionStorage.getItem('correctMessage') === 'userRegistered') {
			sessionStorage.removeItem('correctMessage')
			if (setTopMessage) setTopMessage('userRegistered')
		} else if (sessionStorage.getItem('correctMessage') === Constants.ERROR.INVALID_TOKEN) {
			sessionStorage.removeItem('correctMessage')
			if (setTopMessage) setTopMessage('invalidToken')
		}
		if (getUrlParameter('company_registration')) {
			if (setIsOpenRegisterCompanyModal) setIsOpenRegisterCompanyModal(true)
		}
	}, [setIsOpenRegisterCompanyModal, setTopMessage])

	useEffect(() => {
		if (isSuccess && environment?.RECAPTCHA_KEY) loadRecaptchaScript(environment.RECAPTCHA_KEY)
	}, [isSuccess, environment])

	function redirectPasswordRecovery() {
		location.href = URLS.PASSWORD_RECOVERY
	}

	function loginSubmit(e: React.FormEvent<HTMLFormElement>) {
		e.preventDefault()
		showLoadingSpinner()
		if (valueUserName && valueUserPassword) {
			if (!valueUserName.match(Constants.REGEX.EMAIL)) {
				setErrorMessageLogin('emailNoValidText')
				setPleaseCheck(false)
				hideLoadingSpinner()
				return false
			}
		} else {
			setBorderRedEmail(!valueUserName)
			setBorderRedPass(!valueUserPassword)
			setErrorMessageLogin('fieldNoFillText')
			setPleaseCheck(false)
			hideLoadingSpinner()
			return false
		}
		setErrorMessageLogin('')
		return Auth.signIn({ username: valueUserName, password: valueUserPassword })
			.then(() => {
				client
					.mutate({
						mutation: queries.LOGIN_USER
					})
					.then((result) => {
						UserInformation.setCurrentUser(result.data.loginUser, undefined)
						validationToRedirection(true)
							.then((userStatus: unknown) => {
								if (userStatus === Constants.USER_STATUS.BLOCKED) {
									setBlockDefaultCompany(true)
									hideLoadingSpinner()
								} else if (userStatus === Constants.USER_STATUS.UNSUBSCRIBED) {
									setUnsubscribedFromDefaultCompany(true)
									hideLoadingSpinner()
								} else if (userStatus === Constants.AUTH.ALLCOMPANIESBLOCKED) {
									localStorage.clear()
									Auth.signOut()
										.then()
										.catch((err) => console.error(err))
									setErrorMessageLogin('fullCompanyBlock')
									hideLoadingSpinner()
								} else {
									return <HomeView />
								}
								return true
							})
							.catch((error) => {
								console.error(error)
								return false
							})
					})
					.catch((error) => {
						Auth.signOut()
							.then()
							.catch((err) => {
								console.error(err)
								return false
							})
						if (
							error.graphQLErrors !== undefined &&
							error.graphQLErrors[0] !== undefined &&
							error.graphQLErrors[0].code === Constants.AUTH.BAD_CREDENTIALS
						) {
							setErrorMessageLogin('userNoValidText')
							setPleaseCheck(true)
							setBorderRedEmail(true)
						} else {
							setErrorMessageLogin('failedText')
							setPleaseCheck(false)
						}
						hideLoadingSpinner()
						return false
					})
			})
			.catch((error) => {
				handleError(error)
				return true
			})
			.finally(() => {
				setPleaseCheck(true)
				return true
			})
	}

	function clickInput(borderRemove: string) {
		if (borderRemove === 'borderEmail') {
			setBorderRedEmail(false)
		}
		if (borderRemove === 'borderPass') {
			setBorderRedPass(false)
		}
	}

	const redirectUserSelectCompany = () => {
		location.href = URLS.SELECT_COMPANY_AT_LOGIN
		setBlockDefaultCompany(false)
		setUnsubscribedFromDefaultCompany(false)
	}
	const handleError = (error: Error) => {
		console.log(error)
		const newErrorCode = showErrorComponent(error)
		setErrorMessageLogin(newErrorCode === 'NotAuthorizedException' ? 'userNoValidText' : newErrorCode)
		hideLoadingSpinner()
	}

	return (
		<NetcurioGrid container alignContent="flex-start" justifyContent="flex-start" className={styles.body}>
			<NetcurioGrid item xs={1} />
			<NetcurioGrid item xs={2}>
				<form onSubmit={loginSubmit}>
					<div className={styles.buttonsPosition}>
						<div>
							<img src={logo} className={styles.logo} alt="Netcurio" />
						</div>
						{topMessage && (
							<div className={styles.topMessageContainer}>
								<div
									className={`${styles.msgStructure} ${
										topMessage === 'userRegistered' || topMessage === 'companyRegister'
											? styles.colorSuccessMsg
											: styles.colorErrorMsg
									}`}
								>
									{t(topMessage)}
								</div>
							</div>
						)}
						<div className={styles.spaceBetweenTitle}>
							<span className={styles.loginLabel}>{t('labelLoginTitle')}</span>
						</div>
						<div className={styles.spaceBetweenInputs}>
							<NetcurioTextField
								error={borderRedEmail}
								size="small"
								variant="outlined"
								fullWidth
								label={t('placeholderUserName')}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setValueUserName(e.target.value.replace(' ', ''))
									clickInput('borderEmail')
								}}
							/>
						</div>
						<div>
							<NetcurioTextField
								type="password"
								error={borderRedPass}
								size="small"
								variant="outlined"
								fullWidth
								label={t('placeHolderPassword')}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setValueUserPassword(e.target.value)
									clickInput('borderPass')
								}}
							/>
						</div>
						{errorMessageLogin && (
							<div>
								<p className={classNames(styles.msgStructure, styles.colorErrorMsg)}>
									{t(errorMessageLogin)}
									<br />
									{pleaseCheck ? t('PleaseCheck') : ''}
								</p>
							</div>
						)}
						<div>
							<p className={styles.forgottenPasswordLink} onClick={redirectPasswordRecovery}>
								{t('forgotMyPasswordText')}
							</p>
						</div>
						<div>
							<NetcurioButton fullWidth variant="contained" size="small" type="submit">
								{t('loginTextButton')}
							</NetcurioButton>
						</div>
						<hr className={styles.divider} />
						<div>
							<NetcurioButton
								fullWidth
								variant="outlined"
								onClick={() => {
									if (setIsOpenRegisterCompanyModal) setIsOpenRegisterCompanyModal(true)
								}}
								size="small"
							>
								{t('registerCompany')}
							</NetcurioButton>
						</div>
					</div>
				</form>
			</NetcurioGrid>
			<NetcurioGrid item xs={1} />
			<NetcurioGrid item xs={8}>
				<link rel="preload" as="image" href={coverImageWebp} />
				<picture className={styles.coverImage}>
					<source type="image/webp" srcSet={coverImageWebp} />
					<source type="image/png" srcSet={coverImage} />
					<img src={coverImage} alt="" />
				</picture>
				<div className={styles.textPosition}>
					<p className={styles.whiteText}>{t('loginTextBanner')}</p>
				</div>
			</NetcurioGrid>
			<RegisterCompanyModal />
			<DefaultCompanyBlockModal open={blockDefaultCompany} onAccept={redirectUserSelectCompany} />
			<DefaultCompanyUnsubscribedModal
				open={unsubscribedFromDefaultCompany}
				onAccept={redirectUserSelectCompany}
			/>
		</NetcurioGrid>
	)
}

export const MainViewLoginComponent = () => {
	return (
		<ShowRegisterCompanyProvider>
			<LoginComponent />
		</ShowRegisterCompanyProvider>
	)
}
