import { URLS, getSerieFolio } from '@netcurio/frontend-common'
import { FileViewerModal, NetcurioButton, NetcurioIcons } from '@netcurio/frontend-components'
import { Header } from '@netcurio/frontend-components/src'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { CancelButton } from '../../components/HeaderButtons/CancelButton'
import { ExportButton } from '../../components/HeaderButtons/ExportButton'
import { useEnvironment } from '../../hooks/useEnvironment'
import { useUserRole } from '../../hooks/useUserRole'
import { FileViewerObject, RequestForCreditMemoItem } from '../../types'
import { beforeUnloadListener } from '../../utilities/beforeUnloadListener'
import Constants from '../../utilities/constants'
import { downloadXMLFile } from '../../utilities/file-handling/download-xml-file'
import { InformationContainer } from './InformationContainer/InformationContainer'
import { CatchedErrorModal } from './Modals/CathchedErrorModal/CatchedErrorModal'
import { RejectPendingMessageCreditMemoDetail } from './Modals/RejectPendingMessageCreditMemoDetail/RejectPendingMessageCreditMemoDetail'
import { CancelCreditMemoModal } from './Modals/cancelCreditMemoModal'
import { SendCreditMemoModal } from './Modals/sendCreditMemoModal'
import { VoidCreditMemoModal } from './Modals/voidCreditMemo'
import styles from './creditMemoDetail.module.scss'
import { LateralColumn } from './lateralColumn'
import { ModalTypes, WorkflowValueEnum } from './types'
import { useCreditMemoDetail } from './useCreditMemoDetail'

export const CreditMemoDetail = () => {
	const [rfcmItems, setRfcmItems] = useState<RequestForCreditMemoItem[]>([])
	const [fileViewer, setFileViewer] = useState<FileViewerObject>({
		open: false,
		titleText: '',
		fileSrc: ''
	})
	const [existsPendingMessage, setExistsPendingMessage] = useState<boolean>(false)
	const [workflow, setWorkflow] = useState(WorkflowValueEnum.Empty)
	const { environment } = useEnvironment()
	const { S3_BUCKET } = environment ? environment : { S3_BUCKET: '' }
	const { CREDIT_MEMOS_FOLDER } = Constants.FOLDER
	const history = useHistory()
	const { t } = useTranslation()

	const {
		creditMemoDetail,
		creditMemoId,
		fetchCreditMemoDetail,
		handleError,
		currentModal,
		setCurrentModal,
		handleSendCreditMemo,
		updateItems,
		cmHasBeenSent,
		errorMessage,
		errorCode,
		setErrorCode,
		setRfcmReference,
		rfcmReference
	} = useCreditMemoDetail(workflow)

	const { isUserSupplier } = useUserRole()
	const isMissingLinkStatus = creditMemoDetail?.status.key === Constants.CREDIT_MEMO_STATUS.MISSING_LINK
	const isErrorStatus = creditMemoDetail?.status.key === Constants.CREDIT_MEMO_STATUS.ERROR
	const isEditionMode = isUserSupplier && (isMissingLinkStatus || isErrorStatus)

	useEffect(() => {
		if (creditMemoDetail) {
			const { uuid, serie, folio } = creditMemoDetail
			const fileName = `${uuid.toLowerCase()}.pdf`
			const fileSrc = `${S3_BUCKET}${CREDIT_MEMOS_FOLDER}/${fileName}`
			const serieFolio = getSerieFolio(serie, folio)
			const titleText = `${t('creditMemo')} ${serieFolio}`
			setFileViewer((state) => ({ ...state, titleText, fileSrc }))
		}
	}, [creditMemoDetail, t, S3_BUCKET, CREDIT_MEMOS_FOLDER])

	useEffect(() => {
		if (creditMemoDetail && !isMissingLinkStatus && !isErrorStatus) {
			if (creditMemoDetail?.request_for_credit_memo?.id) {
				setWorkflow(WorkflowValueEnum.OtherCreditMemo)
			} else {
				setWorkflow(WorkflowValueEnum.AdvanceDiscount)
			}
		}
	}, [creditMemoDetail, isMissingLinkStatus, isErrorStatus])

	const handleDocumentSelected = (rfcmId: string | undefined, items: RequestForCreditMemoItem[]) => {
		setRfcmReference(rfcmId)
		setRfcmItems(items)
		if (rfcmId === undefined) {
			beforeUnloadListener('remove')
		} else {
			beforeUnloadListener('add')
		}
	}

	const associateItems = (chosenItem: RequestForCreditMemoItem, creditMemoItemIndex: number) => {
		if (!creditMemoDetail) return

		const updatedItems = creditMemoDetail.items.map((item, i) =>
			i === creditMemoItemIndex
				? {
						...item,
						reference_position: chosenItem.position,
						request_for_credit_memo_item: chosenItem
					}
				: item
		)
		updateItems(updatedItems)
	}

	const hasAllReferenceLinked = creditMemoDetail?.items.every((item) => item.reference_position !== null)

	const closePDFModal = () => setFileViewer((state) => ({ ...state, open: false }))

	const cancelButtonAction = () => {
		if (existsPendingMessage) {
			setCurrentModal(ModalTypes.RejectPendingMessage)
		} else history.push(URLS.CREDIT_MEMO_LIST)
	}

	const showHeaderMessage =
		isMissingLinkStatus &&
		(workflow === WorkflowValueEnum.Empty || workflow === WorkflowValueEnum.AdvanceDiscount)
			? false
			: isEditionMode && (!hasAllReferenceLinked || rfcmItems.length === 0)

	return (
		<div className={styles.creditMemoDetailContainer}>
			<Header>
				<div>
					<CancelButton
						onClick={cancelButtonAction}
						translationKey="comebackListText"
						icon={<NetcurioIcons.ArrowBack className={classNames(styles.icon)} />}
					/>

					<ExportButton
						onClick={() => {
							downloadXMLFile(
								S3_BUCKET,
								creditMemoDetail!.uuid,
								Constants.FOLDER.CREDIT_MEMOS_FOLDER + '/'
							)
						}}
						translationKey="downloadText"
					/>
					<NetcurioButton
						variant="outlined"
						size={'small'}
						onClick={() => setFileViewer((state) => ({ ...state, open: true }))}
						endIcon={
							<NetcurioIcons.Visibility className={classNames(styles.icon, styles.iconPDF)} />
						}
					>
						<span> {t('showCreditMemoText')} </span>
					</NetcurioButton>
				</div>
				<div></div>
			</Header>
			<div className={styles.creditMemoDetailMiddle}>
				<InformationContainer
					creditMemoDetail={creditMemoDetail!}
					showHeaderMessage={showHeaderMessage}
					rfcmItems={rfcmItems}
					onRfcmItemAssociation={associateItems}
					cmHasBeenSent={cmHasBeenSent}
					workflow={workflow}
				/>
				<LateralColumn
					creditMemoDetail={creditMemoDetail!}
					creditMemoId={creditMemoId}
					setErrorCode={setErrorCode}
					setModalType={setCurrentModal}
					isEditionMode={isEditionMode}
					onDocumentSelected={handleDocumentSelected}
					disabledSendButton={
						workflow === WorkflowValueEnum.AdvanceDiscount ? false : !hasAllReferenceLinked
					}
					rfcmReference={rfcmReference}
					sendAssociation={() => setCurrentModal(ModalTypes.SendAssociation)}
					refreshDocumentData={fetchCreditMemoDetail}
					hasPendingMsg={(pendingMsg) => setExistsPendingMessage(pendingMsg)}
					workflow={workflow}
					setWorkflow={setWorkflow}
				/>
				<FileViewerModal onClose={closePDFModal} {...fileViewer} />
			</div>
			<CancelCreditMemoModal
				open={currentModal === ModalTypes.Cancel}
				onSuccess={() => fetchCreditMemoDetail()}
				handleError={handleError}
				onClose={() => {
					setCurrentModal(null)
				}}
			/>
			<VoidCreditMemoModal
				open={currentModal === ModalTypes.Void}
				onSuccess={() => fetchCreditMemoDetail()}
				handleError={handleError}
				onClose={() => {
					setCurrentModal(null)
				}}
			/>
			<SendCreditMemoModal
				open={currentModal === ModalTypes.SendAssociation}
				onAccept={handleSendCreditMemo}
				onClose={() => {
					setCurrentModal(null)
				}}
			/>
			<CatchedErrorModal
				open={currentModal === ModalTypes.Error}
				errorCode={errorCode!}
				errorGraphQlMessage={errorMessage}
			/>
			<RejectPendingMessageCreditMemoDetail
				open={currentModal === ModalTypes.RejectPendingMessage}
				close={() => setCurrentModal(null)}
				onAccept={() => history.push(URLS.CREDIT_MEMO_LIST)}
			/>
		</div>
	)
}
